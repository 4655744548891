import {DataGridPro} from '@mui/x-data-grid-pro'
import ExcelDownLoadModal from 'pages/common/ExcelDownloadModal'
import {customerCheckReportColumns} from 'pages/customer/dataConfig/declearColumns'
import {customerCheckReportFilter} from 'pages/customer/dataConfig/declearFilters'
import {customerCheckReportRows} from 'pages/customer/dataConfig/declearRows'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {checkValueUpdate, getCheckReportList} from 'utils/apiCalling/api'
import {failAlert} from 'utils/CustomAlert/Alert'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {scrollTop} from 'utils/func/justUtils'
import {toolTipText} from 'utils/toolTips/toolTipText'

const CustomerCheckReport = (props: any) => {
  const [checkReportList, setCheckReportList] = useState<any>(null)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<any>(null)
  const [filter, setFilter] = useState<any>(customerCheckReportFilter)

  const [updateValue, setUpdateValue] = useState<any>({
    startImei: null,
    startIdxDate: null,
    startValue: null,
    imei: null,
    idxDate: null,
    value: null,
    updateId: userInfo?.userId,
  })
  const getCheckReportListCall = async () => {
    await getCheckReportList(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setCheckReportList(res)
      },
      count: (count: any) => {
        setTotalCount(count)
      },
      fail: (err: any) => {
        failAlert()
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const checkValueUpdateCall = async () => {
    await checkValueUpdate(updateValue, {
      success: (res: any) => {
        if (res.resultCode === 0) {
          getCheckReportListCall()
          setUpdateValue({
            startImei: null,
            startIdxDate: null,
            startValue: null,
            imei: null,
            idxDate: null,
            value: null,
            updateId: userInfo?.userId,
          })
        }
      },
      fail: (err: any) => {
        failAlert()
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const rows = customerCheckReportRows(checkReportList)
  const columns: any = customerCheckReportColumns(
    checkReportList,
    updateValue,
    setUpdateValue,
    checkValueUpdateCall,
    setCheckReportList
  )

  useEffect(() => {
    scrollTop()
    getCheckReportListCall()
  }, [filter.date, officeInfo, cooperInfo, filter.page, filter.rowPerPage])

  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerCheckReport.description}
        tooltipText={toolTipText.customerCheckReport.text}
        filter={filter}
        id={'customer-checkReport'}
        setFilter={setFilter}
        searchCallback={getCheckReportListCall}
        total={totalCount}
        control={['selectMonth']}
        actions={['download']}
        searchOptions={[
          'customerNumber',
          'customerName',
          'address',
          'meterDiameter',
          'managerName',
        ]}
        checkData={null}
        onDelete={null}
        onCancel={null}
        setTypeName={null}
      >
        <DataGridPro
          rowHeight={150}
          headerHeight={30}
          experimentalFeatures={{columnGrouping: true}}
          rows={rows}
          columns={columns}
          rowCount={rows?.length}
          disableColumnMenu
          loading={loading}
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
          autoHeight={true}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
      </MeterariumPageLayout>
      <ToastContainer />

      <ExcelDownLoadModal type={'검침 리포트'} />
    </>
  )
}
export default CustomerCheckReport
