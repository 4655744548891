import {Box, Button, Checkbox, FormControlLabel, FormGroup, TextField} from '@mui/material'
import {DataGridPro, GridColumnGroupingModel, GridSortModel} from '@mui/x-data-grid-pro'
import {chargeBackHost, envBackHost, serverLocalName} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import qs from 'qs'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import {customConfirm, savedFailAlert} from 'utils/CustomAlert/Alert'
import ChargeSubOffcanvas from '../modules/chargeOffCanvas'
import {
  blueWaterColumns,
  crebizColumns,
  saeol1Columns,
  saeolBoseongColumns,
  saeolColumns,
  saeolEumseongColumns,
  wimsCheonanColumns,
  wimsColumns,
  wimsSuncheonColumns,
} from './ChargeInfoGird'

const ChargeInfo = (props: any) => {
  const checkBoxInit = (serverName: any) => {
    switch (serverName) {
      case 'cheonan':
      case 'dev':
        return ['021101', '021075', '021115']
      default:
        return ['1']
    }
  }

  const {setSelectStep} = props
  const serverName = serverLocalName
  const location = useLocation()
  const navigate = useNavigate()
  const data = location.state?.data
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [officeSetting, setOfficeSetting] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState<any>('')
  const [selectedOptions, setSelectedOptions] = useState<any[]>(checkBoxInit(serverName))
  const [searchStatus, setSearchStatus] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const [checkMeterData, setCheckMeterData] = useState<any>(data)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {field: 'customer_number', sort: 'asc'},
  ])

  const cul = window.location.href.split('?')[1]
  const url = qs.parse(cul)
  const selectedDate = url.date

  const keyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      setSearchStatus(true)
    } else {
      setSearchStatus(false)
    }
  }

  const getOfficeDetailInfo = async () => {
    if (officeInfo.value !== 'yit') {
      axiosWithAuth()
        .get(`${envBackHost}/office/${officeInfo.value}`)
        .then((res: any) => {
          let response = res.data.res[0]
          setOfficeSetting(response?.officeCustomerTypeModel[0])
        })
    }
  }

  const handleChange = (event: any) => {
    const {name} = event.target

    const allOptions = checkBoxInitHandler(serverLocalName).map(([option]) => option)

    if (name === 'all') {
      setSelectedOptions(selectedOptions.length === allOptions.length ? [] : allOptions)
    } else {
      setSelectedOptions((prev) => {
        if (prev.length === 1 && prev.includes(name)) {
          return prev
        }

        const newSelectedOptions = prev.includes(name)
          ? prev.filter((option) => option !== name)
          : [...prev.filter((option) => option !== 'all'), name]
        const allSelected = newSelectedOptions.length === allOptions.length

        return allSelected ? allOptions : newSelectedOptions
      })
    }
  }

  const checkBoxInitHandler = (serverName: any) => {
    switch (serverName) {
      case 'cheonan':
      case 'dev':
        return [
          ['021101', '유재영'],
          ['021075', '서동춘'],
          ['021115', '이인서'],
        ]
      case 'boseong':
        return [
          ['1', '영아이티'],
          ['4', '케이스마트피아'],
          ['5', '청호정밀'],
        ]
      case 'suncheon':
        return [
          ['1', '영아이티'],
          ['4', '케이스마트피아'],
        ]

      default:
        return []
    }
  }

  const getCheckMeterData = () => {
    setLoading(true)
    axiosWithAuth()
      .get(
        `${chargeBackHost}/api/select/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`
      )
      .then((res) => {
        setLoading(false)
        if (res.data !== false) {
          setCheckMeterData(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getCheckMeterBoseongData = () => {
    const cooperNumberString = selectedOptions.map((option) => `'${option}'`).join(',')
    setLoading(true)

    axiosWithAuth()
      .get(
        `${chargeBackHost}/api/select/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${
          userInfo?.userName
        }&cooper_number=${cooperNumberString}`
      )
      .then((res) => {
        setLoading(false)
        if (res.data !== false) {
          setCheckMeterData(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getCheckMeterCheonanData = () => {
    const managerNumberString = selectedOptions.map((option) => `'${option}'`).join(',')
    setLoading(true)

    axiosWithAuth()
      .get(
        `${chargeBackHost}/api/select/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${
          userInfo?.userName
        }&manager_name=${managerNumberString}`
      )
      .then((res) => {
        setLoading(false)
        if (res.data !== false) {
          setCheckMeterData(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const postCheckMeterHandler = () => {
    switch (officeInfo?.value) {
      //함양
      //증평지하수
      case '19':
      case '1900':
      case '1903':
        return postHamYangCheckMeterData()

      case '7602':
        return postJeungPyeongCheckMeterData()
      default:
        return postCheckMeterData()
    }
  }
  const postHamYangCheckMeterData = () => {
    setLoading(true)
    axiosWithAuth()
      .post(
        `${chargeBackHost}/api/update/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {
          charge_data: checkMeterData,
          account_name: userInfo.userName,
        }
      )
      .then(async (res) => {
        setLoading(false)
        if (res.data !== false) {
          await swal({
            title: '저장이 완료되었습니다.',
            text: ' ',
            buttons: [false],
            icon: 'success',
            timer: 1500,
          })
        } else {
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const postJeungPyeongCheckMeterData = () => {
    const updatesData = checkMeterData.map((item: any) => {
      // now_used가 null인 경우 계산 후 업데이트
      if (item.check_value === null) {
        item.check_value = item.check_value_default
      }
      return item
    })

    setLoading(true)
    axiosWithAuth()
      .post(
        `${chargeBackHost}/api/update/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {
          charge_data: updatesData,
          account_name: userInfo.userName,
        }
      )
      .then(async (res) => {
        setLoading(false)
        if (res.data !== false) {
          await swal({
            title: '저장이 완료되었습니다.',
            text: ' ',
            buttons: [false],
            icon: 'success',
            timer: 1500,
          })
        } else {
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const postCheckMeterData = () => {
    const updatesData = checkMeterData.map((item: any) => {
      // now_used가 null인 경우 계산 후 업데이트
      if (item.now_used === null) {
        item.now_used = Number(item.check_value_default) - Number(item.bef_check_value)
      }
      return item
    })

    setLoading(true)
    axiosWithAuth()
      .post(
        `${chargeBackHost}/api/update/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {
          charge_data: updatesData,
          account_name: userInfo.userName,
        }
      )
      .then(async (res) => {
        setLoading(false)
        setIsSaved(true)
        if (res.data !== false) {
          await swal({
            title: '저장이 완료되었습니다.',
            text: ' ',
            buttons: [false],
            icon: 'success',
            timer: 1500,
          })
        } else {
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
        getCheckMeterCheonanData()
      })

      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const resultFileHandler = (serverName: any) => {
    switch (serverName) {
      case 'boseong':
      case 'suncheon':
        return resultFileBoseongDownload()
      case 'cheonan':
      case 'dev':
        return resultFileCheonanDownload()

      default:
        return resultFileDownload()
    }
  }

  const resultFileDownload = () => {
    axiosWithAuth()
      .get(
        `${chargeBackHost}/down/result/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {responseType: 'blob'}
      )
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${officeInfo?.label}_${dayjs().format('YY-MM-DD')}.dat`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const resultFileBoseongDownload = () => {
    const mapInit: any = checkBoxInitHandler(serverLocalName)
    const cooperNumberString = selectedOptions.map((option) => `'${option}'`).join(',')
    const cooperNameMap = new Map<string, string>(mapInit)

    const cooperNameString = selectedOptions
      .map((option) => cooperNameMap.get(option) || '')
      .filter((name) => name)
      .join('_')

    axiosWithAuth()
      .get(
        `${chargeBackHost}/down/result/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${
          userInfo?.userName
        }&cooper_number=${cooperNumberString}`,
        {responseType: 'blob'}
      )
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${officeInfo?.label}_${dayjs().format('YY-MM-DD')}_${cooperNameString}.dat`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const resultFileCheonanDownload = () => {
    const mapInit: any = checkBoxInitHandler(serverLocalName)
    const managerNumberString = selectedOptions.map((option) => `'${option}'`).join(',')
    const cooperNameMap = new Map<string, string>(mapInit)

    const managerNameString = selectedOptions
      .map((option) => cooperNameMap.get(option) || '')
      .filter((name) => name)
      .join('_')

    axiosWithAuth()
      .get(
        `${chargeBackHost}/down/result/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${
          userInfo?.userName
        }&manager_name=${managerNumberString}`,
        {responseType: 'blob'}
      )
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${officeInfo?.label}_${dayjs().format('YY-MM-DD')}_${managerNameString}.dat`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const fileDownloadHandler = (serverName: any) => {
    switch (serverName) {
      case 'boseong':
      case 'suncheon':
        return fileBoseongDownload()
      case 'cheonan':
      case 'dev':
        return fileCheonanDownload()
      default:
        return fileDownload()
    }
  }
  const fileDownload = async () => {
    setLoading(true)
    await axiosWithAuth()
      .get(
        `${chargeBackHost}/down/excel/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {responseType: 'arraybuffer'}
      )
      .then(async (res) => {
        if (res.status === 200) {
          console.log('res', res)
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${officeInfo?.label.replace(' ', '_')}_${selectedDate}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          setLoading(false)
        } else {
          console.log('실패')
          setLoading(false)
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const fileBoseongDownload = async () => {
    const mapInit: any = checkBoxInitHandler(serverLocalName)
    const cooperNumberString = selectedOptions.map((option) => `'${option}'`).join(',')
    const cooperNameMap = new Map<string, string>(mapInit)

    const cooperNameString = selectedOptions
      .map((option) => cooperNameMap.get(option) || '')
      .filter((name) => name)
      .join('_')

    setLoading(true)
    await axiosWithAuth()
      .get(
        `${chargeBackHost}/down/excel/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${
          userInfo?.userName
        }&cooper_number=${cooperNumberString}`,
        {responseType: 'arraybuffer'}
      )
      .then(async (res) => {
        if (res.status === 200) {
          console.log('res', res)
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${officeInfo?.label.replace(
            ' ',
            '_'
          )}_${selectedDate}_${cooperNameString}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          setLoading(false)
        } else {
          console.log('실패')
          setLoading(false)
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }
  const fileCheonanDownload = async () => {
    if (isSaved === false) {
      await savedFailAlert()
      return false
    }
    const mapInit: any = checkBoxInitHandler(serverLocalName)
    const managerNumberString = selectedOptions.map((option) => `'${option}'`).join(',')
    const cooperNameMap = new Map<string, string>(mapInit)

    const managerNameString = selectedOptions
      .map((option) => cooperNameMap.get(option) || '')
      .filter((name) => name)
      .join('_')

    setLoading(true)
    await axiosWithAuth()
      .get(
        `${chargeBackHost}/down/excel/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${
          userInfo?.userName
        }&manager_name=${managerNumberString}`,
        {responseType: 'arraybuffer'}
      )
      .then(async (res) => {
        if (res.status === 200) {
          console.log('res', res)
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${officeInfo?.label.replace(
            ' ',
            '_'
          )}_${selectedDate}_${managerNameString}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          setLoading(false)
          await swal({
            title: '성공',
            text: '성공적으로 처리되었습니다.',
            icon: 'success',
            buttons: [false],
            timer: 2000,
          })
        } else {
          console.log('실패')
          setLoading(false)
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const rows = _.map(checkMeterData, (el, i) => {
    return {
      id: i + 1,
      address_road: el.address_road,
      address_number: el.address_number,
      bef_check_value: el.bef_check_value,
      bef_used: el.bef_used,
      check_cycle: el.check_cycle,
      check_day: el.check_day,
      check_code: el.check_code,
      check_month: el.check_month,
      check_value: el.check_value,
      check_value_default: el.check_value_default,
      check_user: el.check_user,
      city_code: el.city_code,
      customer_name: el.customer_name,
      customer_number: el.customer_number,
      customer_status: el.customer_status,
      device_type: el.device_type,
      exceptstatus: el.exceptstatus,
      file_path: el.file_path,
      idx_date: el.idx_date,
      ip: el.ip,
      now_check_code: el.now_check_code,
      now_check_type: el.now_check_type,
      now_used: el.now_used,
      phone_number: el.phone_number,
      process_status: el.process_status,
      recognized_type: el.recognized_type,
      resource_code: el.resource_code,
      rotate: el.rotate,
      sub_number: el.sub_number,
      trsdat: el.trsdat,
      trstim: el.trstim,
      trsusr: el.trsusr,
      officeId: el.office_id,
      efos_connect_at: el.efos_connect_at,
      check_decimal_value: el.check_decimal_value,
      check_date: el?.check_date,
      now_check_date: el?.now_check_date,
      water_type: el?.water_type,
      three_bef_code: el?.three_bef_code,
      three_bef_value: el?.three_bef_value,
      three_bef_used: el?.three_bef_used,
      two_bef_code: el?.two_bef_value,
      two_bef_value: el?.two_bef_value,
      two_bef_used: el?.two_bef_used,
      bef_check_code: el?.bef_check_code,
      meter_diameter: el?.meter_diameter,
      as_status: el?.as_status,
      filter3: el?.filter3,
      edited_status: el?.edited_status,
      cooperName: el?.cooper_name,
      cooperIdx: el?.cooper_idx,
      processCode: el?.process_code,
    }
  })
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: '이름',
      headerAlign: 'center',
      description: '수용가에 관한 정보입니다.',
      children: [{field: 'customerInfo'}],
    },
    {
      groupId: '당월검침정보',
      headerAlign: 'center',
      description: '당월 검침내역에 관한 정보입니다.',
      children: [{field: 'checkInfo'}],
    },
    {
      groupId: '전월검침정보',
      headerAlign: 'center',
      description: '전월 검침내역에 관한 정보입니다.',
      children: [
        {field: 'befCheckInfo'},
        {field: 'bef_check_code'},
        {field: 'bef_check_value'},
        {field: 'bef_used'},
      ],
    },
    {
      groupId: '변경일',
      headerAlign: 'center',
      description: '변경내역에 관한 정보입니다.',
      children: [{field: 'trsDateInfo'}],
    },
    {
      groupId: '변경자',
      headerAlign: 'center',
      description: '변경내역에 관한 정보입니다.',
      children: [{field: 'trsInfo'}],
    },
  ]

  const filterRows = (gridRow: any) => {
    if (searchStatus === true) {
      return _.filter(
        gridRow,
        (row: any) =>
          row.customer_number?.includes(searchValue) ||
          row.customer_name?.includes(searchValue) ||
          row.address_road?.includes(searchValue) ||
          row.address_number?.includes(searchValue)
      )
    }
    return rows
  }
  console.log(isSaved)
  const gridColumnHandler = (program: any, server: string) => {
    switch (program) {
      case 'WIMS':
        switch (server) {
          case 'cheonan':
          case 'dev':
            // console.log(isSaved)
            return wimsCheonanColumns(checkMeterData, setCheckMeterData, officeSetting, setIsSaved)
          case 'suncheon':
            return wimsSuncheonColumns(checkMeterData, setCheckMeterData)
          default:
            return wimsColumns(checkMeterData, setCheckMeterData)
        }
      case 'BLUEWATER':
        return blueWaterColumns(checkMeterData, setCheckMeterData)
      case 'SAEOL':
        switch (server) {
          case 'eumseong':
            return saeolEumseongColumns(checkMeterData, setCheckMeterData, officeSetting)
          case 'boseong':
            return saeolBoseongColumns(checkMeterData, setCheckMeterData)

          default:
            return saeolColumns(checkMeterData, setCheckMeterData)
        }
      case 'SAEOL1':
        return saeol1Columns(checkMeterData, setCheckMeterData)
      case 'CREBIZ':
        return crebizColumns(checkMeterData, setCheckMeterData)
      default:
        return saeolColumns(checkMeterData, setCheckMeterData)
    }
  }

  const chargeSubHandler = (program: any, serverName: any) => {
    //부관 핸들러
    switch (program) {
      case 'SAEOL':
      case 'SAEOL1':
        switch (serverName) {
          case 'eumseong':
          case 'boseong':
            return false
          default:
            return true
        }
      default:
        return false
    }
  }

  const datFileHandler = (program: any, serverName: any) => {
    // dat 파일 관련 handler
    switch (program) {
      case 'SAEOL':
      case 'SAEOL1':
        return (
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={() => resultFileHandler(serverName)}
          >
            결과파일 다운로드
          </Button>
        )

      case 'WIMS':
        switch (serverName) {
          case 'gyeryong':
          case 'cheonan':
            return <></>

          case 'suncheon':
          case 'cheonan':
          case 'dev':
            return (
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => resultFileHandler(serverName)}
              >
                결과파일 다운로드
              </Button>
            )

          default:
            return (
              <Button variant={'contained'} color={'secondary'}>
                전송
              </Button>
            )
        }

      default:
        return <></>
    }
  }

  const checkBoxHandler = (serverName: any) => {
    switch (serverName) {
      case 'boseong':
      case 'suncheon':
      case 'cheonan':
      case 'dev':
        return true
      default:
        return false
    }
  }

  useEffect(() => {
    switch (serverName) {
      case 'boseong':
      case 'suncheon':
        getCheckMeterBoseongData()
        break
      case 'cheonan':
      case 'dev':
        getCheckMeterCheonanData()
        break
      case 'eumseong':
        getOfficeDetailInfo()
        getCheckMeterData()
        break

      default:
        getCheckMeterData()
        break
    }

    setSelectStep({step1: true, step2: true})
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      localStorage.setItem('reload', 'true')
    }

    const enableBeforeUnload = () => {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    const disableBeforeUnload = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    enableBeforeUnload()

    if (localStorage.getItem('reload')) {
      switch (serverName) {
        case 'boseong':
        case 'suncheon':
          getCheckMeterBoseongData()
          break
        case 'cheonan':
        case 'dev':
          getCheckMeterCheonanData()
          break
        default:
          getCheckMeterData()
          break
      }

      localStorage.removeItem('reload')
    }

    return () => {
      disableBeforeUnload()
    }
  }, [])

  useEffect(() => {
    switch (serverName) {
      case 'boseong':
      case 'suncheon':
        getCheckMeterBoseongData()
        break

      case 'cheonan':
      case 'dev':
        getCheckMeterCheonanData()
        break

      default:
        getCheckMeterData()
        break
    }
  }, [selectedOptions])

  return (
    <>
      {
        <>
          <div className='card flex-grow-1' id='customer-register'>
            <div className='card-header '>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>
                  <strong className={'text-primary'}>{officeInfo?.label}</strong>의 요금연계를
                  진행합니다.
                </h3>
              </div>
              <div className='card-title m-0 me-4 mt-3'>
                {chargeSubHandler(url?.program, serverName) && (
                  <Button
                    className='me-9'
                    variant={'contained'}
                    color={'error'}
                    data-bs-toggle={'offcanvas'}
                    data-bs-target='#ChargeSub'
                    aria-controls='ChargeSub'
                  >
                    부관 조회
                  </Button>
                )}
                <Button
                  variant={'contained'}
                  onClick={async () => {
                    if (
                      await customConfirm(
                        '처음으로 돌아시겠습니까?',
                        'warning',
                        '처음으로 돌아가기'
                      )
                    ) {
                      setSelectStep({step1: false, step2: false})
                      navigate('/charge/select')
                    }
                  }}
                >
                  처음으로
                </Button>
              </div>
            </div>
            <div className='card-body p-3'>
              <div className={'container-fluid'}>
                <div className={'row row-cols mt-3'} id={'content'}>
                  <div id={'table-section'} className={'col-lg '}>
                    <div id={'table-header'} className={'d-flex justify-content-between'}>
                      <div id={'table-header-start'} className={'align-self-center'}>
                        <span className={'fs-3'}>
                          전체 : <strong>{rows?.length}</strong> 개
                        </span>
                      </div>
                      <div
                        className={'d-flex justify-content-between gap-3 pb-3'}
                        id={'table-header-end'}
                      >
                        {checkBoxHandler(serverName) && (
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name='all'
                                  checked={
                                    selectedOptions.length ===
                                    checkBoxInitHandler(serverLocalName).length
                                  }
                                  onChange={handleChange}
                                />
                              }
                              label='전체'
                            />

                            {checkBoxInitHandler(serverLocalName).map(([option, labelName]) => {
                              return (
                                <FormControlLabel
                                  key={option}
                                  control={
                                    <Checkbox
                                      name={option}
                                      checked={selectedOptions.includes(option)}
                                      onChange={handleChange}
                                    />
                                  }
                                  label={labelName}
                                />
                              )
                            })}
                          </FormGroup>
                        )}

                        <TextField
                          id='search-input'
                          placeholder='검색어를 입력하세요'
                          size='small'
                          className='me-5'
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          onKeyDown={(e) => keyDownHandler(e)}
                        />
                        <Button
                          variant={'contained'}
                          color={'warning'}
                          onClick={() => postCheckMeterHandler()}
                        >
                          저장
                        </Button>
                        <Button
                          variant={'contained'}
                          color={'success'}
                          onClick={() => fileDownloadHandler(serverName)}
                        >
                          {!loading ? (
                            '다운로드'
                          ) : (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          )}
                        </Button>
                        {datFileHandler(url?.program, serverName)}
                      </div>
                    </div>
                    <Box id={'table-content'} sx={{height: 'calc(100vh - 480px)', width: '100%'}}>
                      <DataGridPro
                        rows={filterRows(rows)}
                        columns={gridColumnHandler(url?.program, serverName)}
                        rowHeight={150}
                        headerHeight={url?.program === 'BLUEWATER' ? 50 : 30}
                        loading={loading}
                        columnGroupingModel={columnGroupingModel}
                        experimentalFeatures={{columnGrouping: true}}
                        disableColumnMenu
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel: any) => setSortModel(newSortModel)}
                        getRowClassName={(params) => {
                          let rows = params?.row
                          //지침 수정전 당월 사용량이 0보다 작은경우
                          if (
                            rows?.now_used === null &&
                            rows?.check_value_default - rows?.bef_check_value < 0
                          ) {
                            return 'warning-row'
                          }
                          if (serverName === 'boseong') {
                            return rows?.edited_status !== 'true' ? '' : 'edited-row'
                          }

                          if (serverName === 'cheonan' || serverName === 'dev') {
                            if (rows?.now_used < 0) {
                              return 'warning-row'
                            }
                            if (rows.check_value_default === '') {
                              return 'warning-row'
                            }
                            if (rows.processCode !== 'NORMAL') {
                              return 'warning-row'
                            }
                            return rows?.edited_status !== 'true' ? '' : 'edited-row'
                          }

                          if (serverName === 'eumseong') {
                            // 당월 사용량이 0보다 작은경우 ( 지침 수정 후)
                            if (rows?.now_used < 0) {
                              return 'warning-row'
                            }
                            // 전월 검침코드, 지침, 사용량 등이 없는경우..(?)
                            if (rows?.bef_check_code === null) {
                              return 'warning-row'
                            }

                            // 수정된 경우
                            return rows?.edited_status !== 'true' ? '' : 'edited-row'
                          } else {
                            return rows?.filter3 === null || rows?.filter3 === 'null'
                              ? ''
                              : 'edited-row'
                          }
                        }}
                        initialState={{
                          pinnedColumns: {
                            left: ['check_date', 'customer_number', 'customerInfo'],
                          },
                        }}
                        sx={{
                          '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                          },
                          '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                          },
                          '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                          },
                          '& .edited-row': {
                            backgroundColor: 'rgba(76, 175, 80, 0.2)',
                          },
                          '& .warning-row': {
                            backgroundColor: 'rgba(255, 250, 205, 0.7)',
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ChargeSubOffcanvas />
        </>
      }
    </>
  )
}

export default ChargeInfo
